import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { AuthContent, AuthenticationContainer, ButtonContainer, Button, SignAgainButton } from './styles'
import { USER_SECURITY_CREDENTIALS } from '../../../configs'
import { reverseObfuscateConstant } from '../../../utils/Constant'
import { isDateExpired } from '../../../app/services/constant'

interface AuthenticationState {
	username: string;
	password: string;
	isShow: boolean,
	isFailed: boolean
}

function DevAuthModal(): ReactElement {
	const [authState, setAuthState] = useState<AuthenticationState>({
		username: '',
		password: '',
		isShow: false,
		isFailed: false
	})

	useEffect(() => {
		
		if(window.location.hostname !== 'www.robertparker.com'){

			const storedExpirationDate = localStorage.getItem('AuthSessionExpiration')

			if(storedExpirationDate === null){
				return setAuthState(state => ({ ...state, isShow: true }))
			}

			if (isDateExpired()) {
				setAuthState(state => ({ ...state, isShow: true, isFailed: false }))
				localStorage.removeItem('AuthSessionExpiration')
			} else {
				setAuthState(state => ({ ...state, isShow: false, isFailed: false }))
			}
		}
	}, [])

	const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		setAuthState(state => ({ ...state, password: event.target.value }))
	}

	const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
		setAuthState(state => ({ ...state, username: event.target.value }))
	}

	const handleLogin = () => {
		const isValidUser = Object.values(USER_SECURITY_CREDENTIALS).some(
			user => reverseObfuscateConstant(user.Username) === authState.username && reverseObfuscateConstant(user.Password) === authState.password
		)
		
		if (isValidUser) {
			const currentDate = new Date()
			const expirationDate = new Date(currentDate.getTime() + 1 * 30 * 24 * 60 * 60 * 1000) // 1 Month
			// const expirationDate = new Date(currentDate.getTime() + 10000)
			const expirationDateString = expirationDate.toISOString()

			localStorage.setItem('AuthSessionExpiration', expirationDateString)
			setAuthState(state => ({ ...state, isShow: false }))
		} else {
			setAuthState(state => ({ ...state, isShow: true, isFailed: true }))
		}

	}

	const handleCancel = () => {
		setAuthState(state => ({ ...state, isShow: true, isFailed: true }))
	}

	return (
		<AuthenticationContainer isShow={authState.isShow}>
			<div className="mnmd-layout-split mnmd-block mnmd-block--fullwidth">
				<div className='container'>
					{authState.isFailed ?
						<div>
							<h2><strong>Unauthorized</strong></h2>
							<p>This server could not verify that you are authorized to access the document requested. Either you supplied the wrong credentials (e.g., bad password), or your browser doesn`&lsquo;t understand how to supply the credentials required.</p>
							<SignAgainButton onClick={() => setAuthState(state => ({ ...state, isShow: true, isFailed: false }))}>Try Again</SignAgainButton>
						</div>
						:
						<AuthContent>
							<div className="alert-header">
								<h2>Sign in</h2>
							</div>
							<div className="form-content">
								<p>https://rp-wine-v2.jam247.dev/</p>
								<form id="signInForm">
									<div className="form-group">
										<label>Username</label>
										<input onChange={(event) => handleUsernameChange(event)} type="text" id="username"/>
									</div>
									<div className="form-group">
										<label>Password</label>
										<input onChange={(event) => handlePasswordChange(event)} type="password" id="password"/>
									</div>
								</form>
							</div>
							<ButtonContainer>
								<Button className='login-btn' onClick={handleLogin}><span>Login</span></Button>
								<Button className='cancel-btn' onClick={handleCancel}><span>Cancel</span></Button>
							</ButtonContainer>
						</AuthContent>
					}
				</div>
			</div>
		</AuthenticationContainer>
	)
}

export default DevAuthModal