import styled from 'styled-components'

const commonFontAttributes = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
`

export const AuthenticationContainer = styled.div<{isShow?: boolean}>`
    width: 100vw;
    height: 100vh;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: ${(props) => (props.isShow ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow: hidden;
`
export const AuthContent = styled.div`
    border-radius: 8px;
    background-color: #2c2c2c;
    padding: 20px;
    border: 1px solid #888;
    width: 400px;
    color: white;
    ${commonFontAttributes}

    & .form-group label {
        font-weight: bold;
    }

    & .form-group input {
        width: 100%;
        padding: 10px;
        margin: 5px 0 10px 0;
        display: inline-block;
        border: 1px solid #ccc;
        box-sizing: border-box;
        background-color: #3c3c3c;
        color: white;
    }

    & .form-content{
        padding-bottom: 20px;
    }

    @media (max-width: 453px) {
        width: auto;
    }
`

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: end;

    & .cancel-btn{
        background-color: #3c3c3c;
    }

    & .login-btn{
        background-color: #f2b52a;
    }

    @media (max-width: 453px) {
        flex-direction: column;
    }
`

export const Button = styled.div`
    width: 80px;
    padding: 10px 20px;
	border-radius: 8px;
	text-align: center;
	cursor: pointer;
    
    & span {
        ${commonFontAttributes}
        width: 179px;
        height: 21px;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        text-align: center;
        color: #fff !important;
    }

	&:hover {
		opacity: 0.8;
	}

    @media (max-width: 453px) {
        width: 100%;
    }
`

export const SignAgainButton = styled.button`
    color: black;
    font-weight: bold;
    background-color: #f2b52a;
    border: none;
    border-radius: 3px;
    padding: 0.5rem 1rem;
`