/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { NoSubscriptionLabel, PaymentHistoryCardAccountLabel, PaymentHistoryContainer, PaymentHistoryDateLabel, PaymentHistoryDownloadButton, PaymentHistoryDownloadLabel, PaymentHistoryMainContainer, PaymentHistoryPaymentLabel, PaymentHistoryStatusLabel, PaymentHistoryUsdLabel, Row, SubscriptionRow } from './styles'
import { useGetPaymentHistoryQuery } from '../../../../app/services'
import { useAppSelector } from '../../../../app/hooks'
import LoadingOverlay from '../../../../components/Loading-overlay'

import './components/printComponent/style.css'
import './style.css'
import { Users as UsersFromStore } from '../../../../app/slices/Users'
import { isMobile } from 'react-device-detect'
import ColumizedString from './components/ColumnizedString'
import html2pdf from 'html2pdf.js'
import { jsPDF } from 'jspdf'
import PDFReceipt from './components/pdfComponent/PDFReceipt'
import { useGetPaymentReceiptMutation } from '../../../../app/services/PaymentMethod'
import { getCSURFToken } from '../../../../utils/CSRFToken'
import NoticeModal from './modals/add-ons'
import { useHistory } from 'react-router-dom'
import ErrorModal from '../../../../components/error-modal'

interface PaymentInfo {
	_id: string;
	currency: string;
	amount: number;
	transaction_date: Date;
	created_at: Date;
	credit_card: {
		number: string;
		type: string;
	}
	transaction_status: string;
	payment_gateway: string;
	braintree: {
		id: string
	}
}

interface IPaymentHistoryState {
	list: PaymentInfo[];
	noPaymentHistory: boolean;
}

function PaymentHistory(): ReactElement {
	const componentRef = useRef<null | HTMLDivElement>(null)
	const { userData } = useAppSelector(UsersFromStore)
	const history = useHistory()
	const isCommercial = userData?.subscription?.planId?.includes('commercial')

	const { data, isLoading: getPaymentLoading, isError } = useGetPaymentHistoryQuery(userData?._id, { skip: !userData?._id })
	const [ executeGetReceiptMutation, { isLoading: giftReceiptLoading } ] = useGetPaymentReceiptMutation()
	const [paymentHistoryState, setpaymentHistoryState] = useState<IPaymentHistoryState>({
		list: [],
		noPaymentHistory: false,
	})
	const [showNotice, setShowNotice] = useState({
		status: false,
		message: ''
	})
	const [showError, setShowError] = useState({
		status: false,
		errorMsg: ''
	})
	
	const [previewPDF, setPreviewPDF] = useState<string | null>(null)

	useEffect(() => {
		setpaymentHistoryState((state) => ({ ...state }))
		if (data) {
			if (data.success) {
				const list = data.data.map((payment: PaymentInfo) => ({
					_id: payment._id,
					currency: payment?.currency,
					amount: payment?.amount,
					created_at: payment.created_at,
					credit_card: {
						number: payment?.credit_card?.number,
						type: payment?.credit_card?.type,
					},
					transaction_status: payment?.transaction_status,
					payment_gateway: payment?.payment_gateway,
				}))
				setpaymentHistoryState((state) => ({ ...state, list: data.data, noPaymentHistory: list.length > 0 ? false : true }))
			} else if (data) {
				setpaymentHistoryState((state) => ({ ...state, noPaymentHistory: true }))
			}
		}

		if (isError) {
			console.log('Something went wrong. Please try again.')
		}
	}, [data, isError])

	useEffect(() => {
		if (previewPDF) {
			if (componentRef.current) {
				const element = componentRef.current
				html2pdf()
					.set({
						filename: 'receipt.pdf',
						image: { type: 'jpeg', quality: 0.98 },
						html2canvas: { scale: 2 },
						jsPDF: { unit: 'mm', format: isCommercial ? 'legal' : 'A4', orientation: 'portrait' }
					})
					.from(element)
					.toPdf()
					.get('pdf')
					.then((pdf: jsPDF) => {
						const pdfDataUrl = pdf.output('datauristring')
						openPDFInNewWindow(pdfDataUrl)
					})
			}
		}
	}, [previewPDF, isCommercial])

	const generatePDFPreview = async (id: string) => {
		try {
			const csrf_token = await getCSURFToken()
			const transactionId = id

			const generateReceipt = await executeGetReceiptMutation({
				params: transactionId,
				token: csrf_token,
			}).unwrap()

			if (!generateReceipt.success) {
				if (generateReceipt.message === 'This user does not have country details.') {
					return setShowNotice({
						status: true,
						message: 'Business address needed to generate receipt. Please update your business address.',
					})
				}

				return setShowError({
					status: true,
					errorMsg: generateReceipt.message,
				})
			}

			setPreviewPDF(generateReceipt)
		} catch (error) {
			const errorResponse = error as any
			const paymentHistoryError = errorResponse?.message || errorResponse?.data?.message

			if (paymentHistoryError === 'This user does not have country details.') {
				return setShowNotice({
					status: true,
					message: 'Business address needed to generate receipt. Please update your business address.',
				})
			}

			return setShowError({
				status: true,
				errorMsg: paymentHistoryError || JSON.stringify(errorResponse),
			})
		}
	}

	const openPDFInNewWindow = (pdfDataUrl: string) => {
		const newWindow = window.open('', '_blank')
		if (newWindow) {
			newWindow.document.write('<html><head><title>RPW Payment Receipt</title></head><body>')
			newWindow.document.write('<iframe src="' + pdfDataUrl + '" width="100%" height="100%" style="border: none;"></iframe>')
			newWindow.document.write('</body></html>')
			newWindow.document.close()
		}
	}

	const paymentHistoryLabelhandler = (data: any) => {
		const payment_gateway = data.payment_gateway
		const creditCardNumber = data?.credit_card?.number?.slice(-4)

		switch (payment_gateway) {
		case 'braintree':
			return (
				<>
					<span>
						<b>{data?.credit_card?.type ?? 'Visa'}</b>
					</span>
						&nbsp;
					<span>ending in {creditCardNumber}</span>
				</>
			)
		case 'ios-purchase':
			return 'Mobile app subscription'

		case 'android-purchase':
			return 'Mobile app subscription'

		case 'complimentary':
			return 'Complimentary'

		default:
			return 'N/A'
		}
	}

	return (
		<div>
			{getPaymentLoading ? (
				<LoadingOverlay />
			) : paymentHistoryState.list && paymentHistoryState.list.length > 0 ? (
				<>
					<PaymentHistoryMainContainer>
						{paymentHistoryState?.list.map((ele: PaymentInfo, index: number) => {
							const transactionDate = moment(ele?.transaction_date).format('MMM DD, YYYY')
							const createdDate = moment(ele?.created_at).format('MMM DD, YYYY')
							const paymentId = ele?._id.toUpperCase()
							const currency = ele?.currency
							const amount = typeof ele?.amount === 'number' ? ele.amount : parseFloat(ele?.amount) || 0
							const amountByFixedBy2 = amount.toFixed(2)
							const transactionStatus = ele?.transaction_status
							const paymentGateway = ele?.payment_gateway
							const braintreeId = ele?.braintree?.id
							const mobile_purchased = ele?.payment_gateway == 'ios-purchase' || ele?.payment_gateway == 'android-purchase'

							return (
								<PaymentHistoryContainer key={index} isMobile={isMobile}>
									<Row>
										<PaymentHistoryDateLabel isMobile={isMobile}>{transactionDate ? transactionDate : createdDate}</PaymentHistoryDateLabel>
									</Row>
									<SubscriptionRow>
										<PaymentHistoryPaymentLabel isMobile={isMobile}>
											<span>Payment ID: </span>
											<span>{paymentId}</span>
										</PaymentHistoryPaymentLabel>
										{!mobile_purchased &&
											<PaymentHistoryUsdLabel isMobile={isMobile}>
												<span>{currency} {(typeof amount == 'number') ? amountByFixedBy2 : amount}</span>
											</PaymentHistoryUsdLabel>
										}
									</SubscriptionRow>
									<SubscriptionRow>
										<PaymentHistoryCardAccountLabel isMobile={isMobile}>
											{paymentHistoryLabelhandler(ele)}
										</PaymentHistoryCardAccountLabel>
										{isMobile ? (
											<PaymentHistoryStatusLabel isMobile={isMobile}>
												<ColumizedString inputString={transactionStatus} />{' '}
											</PaymentHistoryStatusLabel>
										) : (
											<PaymentHistoryStatusLabel isMobile={isMobile}>
												<span>{transactionStatus}</span>
											</PaymentHistoryStatusLabel>
										)}
									</SubscriptionRow>
									<Row>
										<PaymentHistoryDownloadButton>
											<PaymentHistoryDownloadLabel
												isDisable={paymentGateway !== 'braintree'}
												disabled={paymentGateway !== 'braintree'}
												onClick={() => {
													// setselectedHistory(ele)
													generatePDFPreview(braintreeId)
												}}
											>
												Download receipt
											</PaymentHistoryDownloadLabel>
										</PaymentHistoryDownloadButton>
									</Row>
								</PaymentHistoryContainer>
							)
						})}
					</PaymentHistoryMainContainer>
					{ giftReceiptLoading && <LoadingOverlay /> }
				</>
			) : (
				<NoSubscriptionLabel>No payment history available</NoSubscriptionLabel>
			)}

			{/* Hidden receipt printing component */}
			<div className="reciept-hide">
				<PDFReceipt refs={componentRef} recieptData={previewPDF} />
			</div>
			{showNotice.status &&
				<NoticeModal
					proceedBtnText='UPDATE ADDRESS'
					messageBody={showNotice.message}
					title={'Notice Message'}
					isOpen={showNotice.status} 
					onClose={() => setShowNotice({ status: false, message: '' })} 
					isBlocked={false}
					onProceed={() => history.push('/my-company/update-company-information')}
				/>}
			{showError.status &&
				<ErrorModal messageBody={showError.errorMsg} isOpen={showError.status} onClose={() => setShowError({ status: false, errorMsg: '' }) } />}
		</div>
	)
}

export default PaymentHistory