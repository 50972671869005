/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ReactElement, useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

// Styles
import { slugify } from '../../../../../utils/ArticlesUtil'
import { ArticleContainer, ArticleImageContainer, FilterContainer } from './styles'
import LoadingOverlay from '../../../../../components/Loading-overlay'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import { CloudImageUtils } from '../../../../../utils/CloudImageUtils'
import useScreen from '../../../../../helper/hooks/useScreen'
import Pagination from '../../../../../components/pagination'
import { useGetWineJournalArticlesQuery, useGetListofAuthorsQuery } from '../../../../../app/services/Article'

type AuthorProps = {
	_id: number | string;
	first_name: string;
	last_name: string;
}

type CategoryItemProps = {
	label: string; 
	categoryId: number | string
}

const categoryList: CategoryItemProps[] = [
	{
		label: 'All Categories',
		categoryId: ''
	},
	{
		label: 'News & Views',
		categoryId: 9
	},
	{
		label: 'Gourmet - Restaurants',
		categoryId: 3
	},
	{
		label: 'Gourmet - Events',
		categoryId: 4
	},
	{
		label: 'Gourmet - Dining At Home',
		categoryId: 5
	},
	{
		label: 'Travel',
		categoryId: 6
	},
	{
		label: 'People & Places',
		categoryId: 8
	},
	{
		label: 'Culture & Education',
		categoryId: 7
	}
]

function FreePubsWineJournal(): ReactElement {

	const { search } = useLocation()
	const queryParams = new URLSearchParams(search)
	const category_id = queryParams.get('categoryId')
	const author_id = queryParams.get('authorId')
	const history = useHistory()

	const [offset, setOffset] = useState(0)
	const [authorId, setAuthorId] = useState('')
	const [categoryId, setCategoryId] = useState('')
	
	const limit = 10
	
	const {data: articleData, isLoading, isFetching, isError} = useGetWineJournalArticlesQuery({offset, limit, authorId, categoryId})
	const {data: authorsList, isLoading: authorsListLoading, isError: authorsListError} = useGetListofAuthorsQuery()

	const [data, setData] = useState<{ articles: [] }>()
	const [authors, setAuthors] = useState<AuthorProps[]>([])
	
	const screen = useScreen()
	const [totalPagination, setTotalPagination] = useState(10)
	const imageRatio = () => {
		switch (screen) {
		case 'tablet portrait':
			return 1.1
		case 'desktop':
			return 1.3
		default:
			return 3.5
		}
	}

	const handleChangePage = (page: number) => {
		setOffset(page)

		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}

	useEffect(() => {
		if(!category_id && !author_id){
			setAuthorId('')
			setCategoryId('')
			return
		}

		if(category_id && (author_id !== null && authors.length)){
			const authorString = author_id.toString()
			setCategoryId(category_id)
			setAuthorId(authorString)
			return
		}

		if(!category_id && (author_id !== null && authors.length)){
			const authorString = author_id.toString()
			setCategoryId('')
			setAuthorId(authorString)
			return
		}

		if((!author_id || !authors.length) && category_id){
			setAuthorId('')
			setCategoryId(category_id)
		}

	}, [search, category_id, author_id, authors])

	const changeUrl = (id: string, type: string) =>{
		if(type === 'author'){
			if(!category_id){
				if(!id)
					return history.push('/free-publications/wine-journal')

				if(id)
					return history.push(`/free-publications/wine-journal?authorId=${id}`)
			}

			if(id)
				return history.push(`/free-publications/wine-journal?authorId=${id}&categoryId=${categoryId}`)

			if(!id)
				return history.push(`/free-publications/wine-journal?categoryId=${categoryId}`)
		}

		if(type === 'category'){
			if(!authorId){
				if(!id)
					return history.push('/free-publications/wine-journal')

				if(id)
					return history.push(`/free-publications/wine-journal?categoryId=${id}`)
			}

			if(id)
				return history.push(`/free-publications/wine-journal?authorId=${authorId}&categoryId=${id}`)

			if(!id)
				return history.push(`/free-publications/wine-journal?authorId=${authorId}`)
		}
	}

	const linkingCategory = (categoryName: string) => {
		const category = categoryList.find((category:CategoryItemProps) => category.label.includes(categoryName))
		if(category){
			if(!authorId)
				return `/free-publications/wine-journal?categoryId=${category.categoryId}`

			return `/free-publications/wine-journal?authorId=${authorId}&categoryId=${category.categoryId}`
		}

		return '/free-publications/wine-journal'
	}

	useEffect(() => {
		if(articleData && articleData?.success){
			const totalRecords = articleData?.data?.meta?.total
			setTotalPagination(totalRecords / limit)
			setData(articleData.data)
			
		}
		if(articleData && !articleData.success){
			setTotalPagination(0)
			setData({ articles: [] })
		}
	}, [articleData])

	useEffect(() => {
		if(authorsList && authorsList?.success)
			setAuthors(authorsList.data)
	}, [authorsList])
	
	return (
		<div>
			{isLoading && <LoadingOverlay />}
			{isFetching && <LoadingOverlay />}
			<div className="mnmd-block mnmd-block--fullwidth mnmd-block--contiguous page-heading page-heading--has-background">
				<div className="container">
					{isMobile ? <h2 className="page-heading__title" style={{ paddingTop: '20px' }}>Wine Journal</h2> : <h2 className="page-heading__title">Wine Journal</h2>}
					{data?.articles ? (
						<div className='page-heading__subtitle'>{articleData?.data?.meta?.total} articles</div>
					) : null}
				</div>
			</div>
			<div className="mnmd-block mnmd-block--fullwidth">
				<div className='container'>
					<div className="mnmd-block mnmd-block--fullwidth">
						{isError ? <p style={{ fontSize: 16 }}>No articles found</p> : <div className="container">
							<FilterContainer>
								{!authorsListLoading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2rem' }}>
									<div/>
									{authorsListError ? <></> : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem' }}>
										<span style={{ fontWeight: 800, width: '9rem' }}>BY AUTHOR</span>
										<select value={authorId} className='component-design' onChange={(e) => changeUrl(e.target.value, 'author')}>
											<option value=''>All Authors</option>
											{authors?.map((author, idx) => <option key={idx} value={author._id}>{author.first_name} {author.last_name}</option>)}
										</select>
									</div>}
									<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem' }}>
										<span style={{ fontWeight: 800, width: '9rem' }}>BY AUTHOR</span>
										<select value={authorId} className='component-design' onChange={(e) => changeUrl(e.target.value, 'author')}>
											<option value=''>All Authors</option>
											{authors?.map((author, idx) => <option key={idx} value={author._id}>{author.first_name} {author.last_name}</option>)}
										</select>
									</div>
								</div>}
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2rem' }}>
									<div/>
									<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem' }}>
										<span style={{ fontWeight: 800, width: '9rem' }}>CATEGORY</span>
										<select value={categoryId} className='component-design' onChange={(e) => changeUrl(e.target.value, 'category')}>
											{categoryList.map((categories: CategoryItemProps, idx: number) => <option key={idx} value={categories.categoryId}>{categories.label}</option>)}
										</select>
									</div>
								</div>
							</FilterContainer>
							{data?.articles && data?.articles.length ? data.articles.map((article: any, key: number) => (
								<ArticleContainer key={key} >
									<ArticleImageContainer>
										<Link className='linktitle' to={`/articles/${article.id}/${slugify(article.title)}`}>{article.image?.url ? <CloudImageUtils imgSrc={article.image?.url ?? '/img/icons/default_article_banner.jpg'} alt="image" ratio={imageRatio()} params='func=cropfit'/> : <img src="/img/icons/default_article_banner.jpg" alt="image" /> }   </Link>
									</ArticleImageContainer>
									<div className='details-container'>
										<div className='title'>
											<Link className='linktitle' to={`/articles/${article.id}/${slugify(article.title)}`}>{article.title}</Link>
										</div>
										<div className='excerpt'>{article.excerpt}</div>
										<Link to={linkingCategory(article.category.name)} className='filter-category-journal'>{article.category.name}</Link>
										<div className="post__meta details" style={{ width: '100%' }}>
											<span className="entry-author">By:&nbsp;
												<span className="entry-author__name">{article.author.name}</span>
											</span>
											<time className="time published detail-container" dateTime={article.published_date} title={moment(article.published_date).format('MMM DD, YYYY')}>
												<i className="mdicon mdicon-schedule"></i>
												<span className='date'>{moment(article.published_date).format('MMM DD, YYYY')}</span>
											</time>
											<Link to={`/search/wines?&article_id=${article.id}`} title={`${article.tasting_note_count} tasting notes`}><i className="mdicon mdicon-chat_bubble_outline"></i>{article.tasting_note_count || 0}</Link>
										</div>
									</div>
								</ArticleContainer>
							)) : <></>}

							{data?.articles && !data?.articles.length ? <p style={{ fontSize: 16 }}>No wine journal found.</p> : <></>}
							<Pagination totalRecords={totalPagination} limit={1} render={handleChangePage} />
						</div>}
						
					</div>
				</div>
			</div>
		</div>
	)
}

export default FreePubsWineJournal