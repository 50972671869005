/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { MouseEvent, ReactElement, useEffect, useState } from 'react'
import { FieldValues, useForm, RegisterOptions } from 'react-hook-form'
import ReCAPTCHA from 'react-google-recaptcha'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

import LoadingOverlay from '../../../../../components/Loading-overlay'
import { useVerifyRedeemCodeQuery } from '../../../../../app/services/Subscription'
import { VerifyRedeemCardBody, RedeemCardContainer, RedeemMainContainer, RedeemTitle, RedeemCardFormBody } from '../styles'
import { useGetTradeDirectoryLookupCountriesQuery } from '../../../../../app/services'
import Dropdown from '../../../../../components/form-components/dropdown/Dropdown'
import Checkbox from '../../../../../components/form-components/checkbox/Checkbox'
import { reverseObfuscateConstant, trimmedEmails } from '../../../../../utils/Constant'
import { RP_ENV } from '../../../../../configs'
import CheckoutButton from '../../../../../components/form-components/buttons/CheckoutButton'
import Input from '../../../../../components/form-components/input/Input'
import { getCSURFToken } from '../../../../../utils/CSRFToken'
import { loginUser } from '../constants'
import { SaveAuthenticationSession } from '../../../../../utils'
import { UpdateUserAuthDetails, Authentication as AuthFromStore } from '../../../../../app/slices/Authentication'
import { setCookie } from '../../../../../utils/CookieUtils'
import CommonModalContainer from '../../../../modals/ModalContainer'
import LoginForms from '../../../Common/Login/LoginForms'
import { useAppSelector } from '../../../../../app/hooks'
import RedeemAccountPageLogged from './components/RedeemAccountPageLogged'
import ErrorModal from '../../../../../components/error-modal'
import {Users as UsersFromStore} from '../../../../../app/slices/Users'
import { setAccessLocalStorage } from '../../../../../utils/ValidateAccessToken'

function Redeem(): ReactElement {
	const [codeVerified, setCodeVerified] = useState<{ isVerified: boolean; planId: string }>({
		isVerified: false,
		planId: '',
	})
	const [countries, setCountries] = useState<string[]>([])
	const [verifyCode, setVerifyCode] = useState('')
	const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
	const [activationError, setActivationError] = useState({
		isActivate: false,
		message: ''
	})
	const [isActivationLoading, setIsActivationLoading] = useState(false)
	

	const dispatch = useDispatch()
	const history = useHistory()

	const authentication = useAppSelector(AuthFromStore)
	const { userData } = useAppSelector(UsersFromStore)

	const { data: verifyCodeData, isLoading: verifyCodeIsLoading } = useVerifyRedeemCodeQuery(verifyCode, { skip: verifyCode === '' })
	const { data: countryData } = useGetTradeDirectoryLookupCountriesQuery('')

	const { handleSubmit, getValues, control, setError, setValue } = useForm()
	const { control: verificationControl, handleSubmit: verificationSubmit, setError: setVerificationError, getValues: redeemptionCodeValues } = useForm()

	const isLogged = authentication.User.accessToken

	const passwordRules: RegisterOptions = {
		required: 'Password is required',
		minLength: {
			value: 10,
			message: 'Password must be at least 10 characters long'
		}
	}

	const confirmPasswordRules: RegisterOptions = {
		required: 'Confirm password is required',
		validate: (value) => {
			return value === getValues('password') || 'The passwords do not match'
		}
	}

	const countryDropdownRules: RegisterOptions = {
		required: 'Country of residence is required',
		validate: (value) => value !== '' || 'Please select a country'
	}

	const aggrementCheckboxRules: RegisterOptions = {
		required: 'Please check aggrement to proceed.',
		validate: (value) => value !== false || 'Please check the aggrement'
	}

	const redemptionInputRules: RegisterOptions = {
		required: 'Redemption code is required'
	}

	useEffect(() => {
		if (countryData?.success) {
			const countryList = countryData.data.map((country: { _id: string; code: string; name: string; }) => country.name)
			setCountries(countryList)
		}

	}, [countryData])

	useEffect(() => {
		if (verifyCode && verifyCodeData?.success) {
			const newPlanId = verifyCodeData.data.complimentarySub.planId
			return setCodeVerified({
				isVerified: true,
				planId: newPlanId
			})
		}

		if (verifyCode && !verifyCodeData?.success) {
			setVerificationError('redemptionCode', {
				type: 'custom',
				message: verifyCodeData?.message
			})

			return setCodeVerified({
				isVerified: false,
				planId: ''
			})
		}
	}, [verifyCodeData?.success])

	const onSubmitHandler = async (formData: FieldValues) => {
		
		if (!formData.recaptcha)
			return setError('isAggrement', {
				type: ' custom',
				message: 'Recaptcha token is required.'
			})
			
		const redemptionCode = redeemptionCodeValues('redemptionCode')

		const url = `${reverseObfuscateConstant(RP_ENV.API_URL_V2)}/users/redemption`
		const countrySelected = countryData.data.find((country: any) => country.name === formData.country)

		try {
			setIsActivationLoading(true)
			const csrf_token = await getCSURFToken()

			const redemptionRegistration = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'x-api-key': RP_ENV.API_KEY,
					'XSRF-TOKEN': csrf_token
				},
				credentials: 'include',
				mode: 'cors',
				body: JSON.stringify({
					title: formData.title,
					firstName: formData.firstName,
					lastName: formData.lastName,
					email: trimmedEmails(formData.email),
					password: formData.password,
					country: {
						...countrySelected
					},
					promoCode: redemptionCode,
					receive_newsletter: formData.isNewsLetter ? 1 : 0,
					receive_marketingletter: formData.isMarketting ? 1 : 0,
					recaptcha_token: formData.recaptcha
				})
			})
			const register = await redemptionRegistration.json()

			if(!register.success)
				return setActivationError({
					isActivate: true,
					message: register.message || register.data.message || ''
				})

			if (register.success) {
				const login = await loginUser(formData.email, formData.password)

				if (login && login.success) {
					const { accessToken, accessTokenExpiresAt, refreshToken, refreshTokenExpiresAt, client, user, country } = login.data

					SaveAuthenticationSession(
						'user',
						accessToken,
						accessTokenExpiresAt,
						refreshToken,
						refreshTokenExpiresAt,
						client._id,
						user._id
					)

					setAccessLocalStorage(accessToken, accessTokenExpiresAt, refreshToken, refreshTokenExpiresAt)

					dispatch(UpdateUserAuthDetails({
						country,
						token: accessToken,
						tokenExpiry: accessTokenExpiresAt,
						refreshToken,
						refreshTokenExpiry: refreshTokenExpiresAt,
						clientId: client._id,
						userId: user._id
					}))

					setCookie('username', formData.email, true)
					history.push('/redemption-codes/success?type=new')
					window.location.reload()
				}
			}

		} catch (error: any) {
			return setActivationError({
				isActivate: true,
				message: error.message || error.data.message || ''
			})
		} finally {
			setIsActivationLoading(false)
		}
	}

	const onVerificationHandler = (data: FieldValues) => {
		if (data.redemptionCode)
			setVerifyCode(data.redemptionCode)
	}

	const planIdDisplay = (planId: string): string => {
		let display = ''
		switch (planId) {
			case 'personal-3-month':
				display = '3 Months Personal'
				break
			case 'personal-monthly':
				display = '1 Month Personal'
				break
			case 'personal-1-year':
				display = '1 Year Personal'
				break
			default:
				display = '1 Year Commercial'
				break
		}

		return display
	}

	const activatingLoggedRedemption = async () => {
		const url = `${reverseObfuscateConstant(RP_ENV.API_URL_V2)}/users/${userData._id}/redemption`
		const redemptionCode = redeemptionCodeValues('redemptionCode')

		const MAX_RETRIES = 0

		const fetchWithRetry = async (url: string, options: RequestInit, retries: number): Promise<Response> => {
			try {
				return await fetch(url, options)
			} catch (error) {
				if (retries > 0) {
					return fetchWithRetry(url, options, retries - 1)
				}
				throw error
			}
		}

		try {
			setIsActivationLoading(true)

			const csrf_token = await getCSURFToken()

			const registration = await fetchWithRetry(
				url,
				{
					method: 'POST',
					headers: {
						'Authorization': 'Bearer ' + authentication.User.accessToken,
						'Content-Type': 'application/json',
						'x-api-key': RP_ENV.API_KEY,
						'XSRF-TOKEN': csrf_token,
						credentials: 'include',
						mode: 'cors',
					},
					body: JSON.stringify({
						promoCode: redemptionCode,
						customerId: userData._id,
					}),
				},
				MAX_RETRIES
			)
			const activationData = await registration.json()

			if(!activationData.success)
				return setActivationError({
					isActivate: true,
					message: activationData.message || activationData.data.message || ''
				})
			
				history.push('/redemption-codes/success?type=exist')
				return window.location.reload()
		} catch (error: any) {
			return setActivationError({
				isActivate: true,
				message: error.message || error.data.message || ''
			})
		} finally {
			setIsActivationLoading(false)
		}
	}

	const triggerLink = (e: MouseEvent<HTMLAnchorElement>, url: string) => {
		e.preventDefault()
		history.push(url)
	}

	const VerifyCard = () => {
		return <VerifyRedeemCardBody>
			<div className='head-body'>
				<h3>Let&apos;s find your subscription</h3>
				<p>Please enter in your redemption code.</p>
			</div>

			<form onSubmit={verificationSubmit(onVerificationHandler)} className='redeem-form' id='codeVerification'>
				<div className='first-input'>
					<Input control={verificationControl} name='redemptionCode' type='text' placeholder='Redemption Code' rules={redemptionInputRules} />
				</div>
				<div className='first-input' style={{ width: '12rem' }}>
					<CheckoutButton name='VERIFY' type='submit' />
				</div>
			</form>
		</VerifyRedeemCardBody>
	}

	const RedeemAccountPage = () => {
		return <RedeemCardFormBody isMobile={isMobile} >
			<div className='head-body'>
				<h3>You&apos;re getting a {planIdDisplay(codeVerified.planId)} Subscription.</h3>
				<p>Create an account below. Already have an account? 
					<a
						href='!#'
						onClick={(e) => { 
							e.preventDefault()
							setIsLoginModalOpen(true)
						}}
					>Sign in.</a>
				</p>
			</div>

			<form className='form' onSubmit={handleSubmit(onSubmitHandler)} id='userProfile'>
				<div className='first-input'>
					<Input control={control} name='firstName' type='text' placeholder='*First Name' rules={{ required: 'First name is required' }} />
					<Input control={control} name='lastName' type='text' placeholder='*Last Name' rules={{ required: 'Last name is required' }} />
				</div>
				<div className='first-input'>
					<Input control={control} name='email' type='email' placeholder='*Email Address' rules={{ required: 'Email address is required' }} />
				</div>
				<div className='first-input'>
					<Input control={control} name='password' placeholder='*Password' rules={passwordRules} type='password' />
					<Input control={control} name='passwordConfirm' placeholder='*Confirm Password' rules={confirmPasswordRules} type='password' />
				</div>
				<div className='first-input'>
					<Dropdown control={control} rules={countryDropdownRules} options={countries} placeholder='*Country of Residence' name='country' />
				</div>
				<br />
				<div className='first-input'>
					<Checkbox name='isNewsLetter' control={control}>
						<span>Set up a weekly alert to notify me about newly published ratings and articles.</span>
					</Checkbox>
				</div>
				<div className='first-input'>
					<Checkbox name='isMarketting' control={control}>
						<span>Include me in your mailing list to receive exclusive promotions, updates, and wine recommendations from Robert Parker Wine Advocate.</span>
					</Checkbox>
				</div>
				<div className='first-input'>
					<Checkbox name='isAggrement' control={control} rules={aggrementCheckboxRules}>
					{/* <span>I have read and agreed to the terms of the <a href='/privacy-notice' onClick={ e => triggerLink(e, '/privacy-notice')} >Privacy Notice</a> and the <a href='/subscription-agreement' onClick={ e => triggerLink(e, '/subscription-agreement')}>Personal Subscription Agreement</a>.</span> */}
					<span>
							By checking this box and subscribing, I agree to the Robert Parker Wine Advocate <a className = 'bold-link' href='/subscription-agreement' onClick={(e) => triggerLink(e, '/subscription-agreement')}>Personal Subscription Agreement</a>.
							I understand that I am requesting immediate access to digital content and therefore waive my right to withdraw from the subscription.<br />
						</span>
					</Checkbox>
				</div>
				<div className='action-input' style={{ flexDirection: 'column', alignItems: 'center' }}>
					<div className='recaptcha-style'>
						<i>Please check the box below to proceed.</i>
						<ReCAPTCHA
							sitekey={`${reverseObfuscateConstant(RP_ENV.CAPTCHA_KEY)}`}
							onChange={(token) => setValue('recaptcha', token)}
						/>
					</div>
					<CheckoutButton style={{ padding: '0.5rem 3rem' }} width='100' height='100%' name='CREATE ACCOUNT' type='submit' />
				</div>
				<i style={{ marginTop: '0.5rem', marginBottom: '1.5rem', fontWeight: 600 }}>
					To find out how we use and process the data you entrust to us, please refer to our <a className='bold-link italic-link' href='/privacy-notice' onClick={e => triggerLink(e, '/privacy-notice')} > privacy policy</a>.
				</i>
			</form>
		</RedeemCardFormBody>
	}

	return <>
		{verifyCodeIsLoading && <LoadingOverlay />}
		{isActivationLoading && <LoadingOverlay />}
		<div className="single-entry">
			<div className="container" style={{ padding: isMobile ? 0 : 15 }}>
				<RedeemMainContainer>
					<RedeemTitle>
						<p>Redeem Subscription</p>
					</RedeemTitle>
					<RedeemCardContainer isVerified={codeVerified.isVerified} isMobile={isMobile}>
						{!codeVerified.isVerified ? <VerifyCard /> : !isLogged ? <RedeemAccountPage /> : <RedeemAccountPageLogged planIdDisplay={planIdDisplay} planId={codeVerified.planId} confirmHandler={activatingLoggedRedemption} />}
					</RedeemCardContainer>
				</RedeemMainContainer>
			</div>
			<CommonModalContainer isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)}>
				<LoginForms setIsOpen={setIsLoginModalOpen} />
			</CommonModalContainer>
			{
				activationError.isActivate && <ErrorModal messageBody={activationError.message} isOpen={activationError.isActivate} onClose={() => setActivationError({ isActivate: false, message: '' })} bgColor='#73333F' closeTextColor='white' />
			}
		</div></>
}

export default Redeem