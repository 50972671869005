/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { RP_ENV } from '../../../../configs'
import { deviceType } from '../../../../helper/constants'
import { reverseObfuscateConstant } from '../../../../utils/Constant'
import { getCSURFToken } from '../../../../utils/CSRFToken'

export const scrollToErrorForm = () => {
	window.scrollTo({ top: 100, behavior: 'smooth' })
}

export const validateEmail = (email: string | undefined) => {
	if(email){
		const testRegex = /(^test@|@test\.)/
		if(testRegex.test(email)){
			return false
		}
		
		const emailRegex = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/
		return emailRegex.test(email)
	}
}

export const getErrors = (formErrors: any) => {
	let error = ''
	for (const i in formErrors) {
		error += formErrors[i]
	}
	return error
}

export const loginUser = async (email: string, password: string) => {

	const loginPayload = {
		username: email,
		password: password,
		device: deviceType(),
	}
	
	const url = `${reverseObfuscateConstant(RP_ENV.API_URL_V2)}/users/login`
	try{
		const csrf_token = await getCSURFToken()

		const login = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-api-key': RP_ENV.API_KEY,
				'XSRF-TOKEN': csrf_token,
				credentials: 'include',
				mode: 'cors'
			},
			body: JSON.stringify(loginPayload)
			
		})
		return await login.json()

		
	} catch(err){
		console.log(err)
	}
}