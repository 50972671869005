/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { useUserForgotPasswordMutation } from '../../../app/services'

// Slices
function ForgotPasswordForm(props: { closeWindow: any, isShown: boolean }): ReactElement {

	const { closeWindow, isShown } = props
	const [email, setEmail] = useState('')
	// const [triggerRecovery] = useTriggerPasswordRecoveryMutation()
	const [errorMessage, setErrorMesssage] = useState('')

	const [userForgotPasswordMutation, { data, isLoading, isError, error}] = useUserForgotPasswordMutation()
	const [isSuccess, setIsSuccess] = useState(false)

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault()
		setErrorMesssage('')
		if (!email || email.trim() === '') {
			setErrorMesssage('Email address is required.')
			return
		}
		const payload = {
			email: email.trim()
		}
		try {
			await userForgotPasswordMutation(payload)
		} catch (error) {
			setErrorMesssage('This email is either invalid or does not exist in our records ...')
		}
	}

	useEffect(() => {
		if (data && data.success) {
			setIsSuccess(true)
		}
	},[data])

	useEffect(() => {
		if (isError) {
			const apiError = error as { data?: { message?: string } }
			if (apiError?.data?.message) {
				setErrorMesssage('This email is either invalid or does not exist in our records...')
			} else {
				setErrorMesssage('An error occurred while processing your request.')
			}
		}
	}, [isError, error])

	// const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	if(event){
	// 		setEmail(event.target.value)
	// 	}
	// 	setErrorMesssage('')
	// }


	return (
		<>
			{isSuccess ? 
				<>
					<div id="forgot-password-tab">
						<p className='text-dark h5 pb-4'>The email has been sent successfully. <strong>Please check your email to reset your password.</strong></p>
						<input type="button" name="submit-login" id="submit-login" className="btn btn-block btn-primary" onClick={() => closeWindow()} value='Close'/>
					</div>
				</>: <>
					<div role="tabpanel" className={`tab-pane fade ${!isShown && !isSuccess && 'show active'}`} id="forgot-password-tab">
						<form onSubmit={(e) => handleSubmit(e)}>
							<p className="login-username">
								<label htmlFor="user_login">Email</label>
								<input type="text" className={!errorMessage ? 'component-design' : ''} onChange={(event) => { setEmail(event.target.value.toLowerCase()) }} style={{ borderColor: errorMessage ? '#e01a00' : '#CCCCCC' }} />
							</p>
							{errorMessage && <p style={{ color: '#e01a00', fontSize: '12px' }}>{<><img style={{ width: '12px' }} src={'img/icons/icon_error_warning.svg'} />&nbsp;{errorMessage}</>}</p>}
							{isLoading && (<p className='text-center'>Loading...</p>)}
							<p className="login-submit">
								<input type="submit" name="submit-forget-form" id="submit-forget-form" className="btn btn-block btn-primary" value="Email reset password link"/>
							</p>
						</form>
					</div></>}
		</>
	)
}

export default ForgotPasswordForm