import React  from 'react'
import OffCanvasMenu from './off-canvas-menu'
import SubscribeModal from './subscribe'
import DevAuthModal from './dev-auth'

const Modals: React.FC = () => { 
	return (
		<React.Fragment>
			<DevAuthModal/>
			<SubscribeModal />
			<OffCanvasMenu />
		</React.Fragment>
	)
}

export default Modals