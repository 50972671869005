/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINTS, RP_ENV } from '../../configs'

import { GetBaseQuery } from '../../utils/'
import { MAX_RETRIES } from './constant'
import { reverseObfuscateConstant } from '../../utils/Constant'

export const SubscriptionAPI = createApi({
	reducerPath: 'SubscriptionAPI',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, reverseObfuscateConstant(RP_ENV.API_URL_V2)),
	endpoints: (builder) => ({
		getSubscriptionDetails: builder.query<any, string>({
			query: (id) => `${API_ENDPOINTS.get_subscription_details}?subscriptionId=${id}`,
		}),
		checkEndDateSubscription: builder.query<any, string>({
			query: (userId) => `${API_ENDPOINTS.check_end_subscription}?userId=${userId}`,
		}),
		updateAutoRenewal: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {
				return{
					url: `/users/${params.userId}/auto-renewal`,
					method: 'PATCH',
					body: params,
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include',
					mode: 'cors'
				}
			}
		}),
		changeSubscriptionPlan: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {
				return{
					url: `/subscriptions/customer/${params.customerId}/plan`,
					method: 'PATCH',
					body: params,
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include',
					mode: 'cors'
				}
			}
		}),
		FreeExpiredSubscription: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {
				return{
					url: API_ENDPOINTS.free_expired_subscription,
					method: 'POST',
					body: params,
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include',
					mode: 'cors',
					max_retries: 0
				}
			}
		}),

	}),
})

export const GiftSubsAPI = createApi({
	reducerPath: 'GiftSubsAPI',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, reverseObfuscateConstant(RP_ENV.API_URL_V2)),
	endpoints: (builder) => ({
		getPromoCodeValidation: builder.query<any, string>({
			query: (promoCode: string) => `${API_ENDPOINTS.get_promo_code_validation}?code=${promoCode}`,
		}),
		getPromoByCode: builder.mutation({
			query: ({promoCode}: { promoCode: string }) => {
				return {
					url: `${API_ENDPOINTS.get_promo_code_validation}?code=${promoCode}`,
					method: 'GET',
				}
			}
		}),
		createGiftRequest: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {
				return {
					url: API_ENDPOINTS.create_gift_subs_request,
					method: 'POST',
					body: params,
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include',
					mode: 'cors',
					max_retries : 0
				}
			}
		}),
		activateNewUserGiftSub: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {
				return {
					url: API_ENDPOINTS.create_gift_subs_request,
					method: 'PATCH',
					body: params,
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include',
					mode: 'cors',
					max_retries: 0
				}
			}
		}),
		activateExistingUserGiftSub: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {
				return {
					url: `/users/${params._id}/gift-subscription`,
					method: 'PATCH',
					body: params,
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include',
					mode: 'cors'
				}
			}
		})
	}),
})

export const SignUpAPI = createApi({
	reducerPath: 'SignUpAPI',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, reverseObfuscateConstant(RP_ENV.API_URL_V2)),
	endpoints: (builder) => ({
		getPromoCodeWithPlan: builder.query<any, { promoCode: string, planID: string }>({
			query: ({ promoCode, planID }) => `${API_ENDPOINTS.get_promo_code_with_plan}?code=${promoCode}&planId=${planID}`,
		}),
		signUpNewUser: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {			
				return {
					url: API_ENDPOINTS.sign_up_new_user,
					method: 'POST',
					body: params,
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include', 
					mode: 'cors',
					max_retries: 0
				}
			}
		}),
		signUpExistingUserUpdgradeCommercial: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {
				return {
					url: `/users/${params.customerId}`,
					method: 'POST',
					body: params,
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include', 
					mode: 'cors',
					max_retries: 0
				}
			}
		}),

	}),
})

export const RedeemSubscriptionAPI = createApi({
	reducerPath: 'RedeemSubscriptionAPI',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, reverseObfuscateConstant(RP_ENV.API_URL_V2), 'application/json'),
	endpoints: (builder) => ({
		verifyRedeemCode: builder.query<any, string>({
			query: (redeemCode) => `${API_ENDPOINTS.verify_redeem_code}?code=${redeemCode}`,
		}),
	}),
})

export const AddOnsAPI = createApi({
	reducerPath: 'AddOnsAPI',
	baseQuery: GetBaseQuery(true, MAX_RETRIES, reverseObfuscateConstant(RP_ENV.API_URL_V2)),
	endpoints: (builder) => ({
		buyAddOns: builder.mutation({
			query: ({params, token}: { params: any, token: string }) => {			
				return {
					url: `/subscriptions/customer/${params.managerID}/add-ons`,
					method: 'POST',
					body: params,
					headers: {
						'XSRF-TOKEN': token
					},
					credentials: 'include',
					mode: 'cors',
					max_retries: 0
				}
			}
		}),
	}),
})

export const {
	useGetPromoCodeValidationQuery,
	useGetPromoByCodeMutation,
	useCreateGiftRequestMutation,
	useActivateNewUserGiftSubMutation,
	useActivateExistingUserGiftSubMutation
} = GiftSubsAPI


export const {
	useGetSubscriptionDetailsQuery,
	useCheckEndDateSubscriptionQuery,
	useUpdateAutoRenewalMutation,
	useChangeSubscriptionPlanMutation,
	useFreeExpiredSubscriptionMutation
} = SubscriptionAPI

export const {
	useVerifyRedeemCodeQuery
} = RedeemSubscriptionAPI

export const {
	useGetPromoCodeWithPlanQuery,
	useSignUpNewUserMutation,
	useSignUpExistingUserUpdgradeCommercialMutation
} = SignUpAPI

export const {
	useBuyAddOnsMutation
} = AddOnsAPI