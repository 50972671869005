import {
	BaseQueryFn,
	fetchBaseQuery,
	retry,
} from '@reduxjs/toolkit/query/react'

import { APP_CONFIG, RP_ENV } from '../configs'

import { RootState } from '../app/store'
import { getCookie } from './CookieUtils'

export function GetBaseQuery(
	withAuthenticatingHeaders: boolean,
	maxRetries: number,
	URL?: string,
	customType?: string
): BaseQueryFn {
	let baseQuery = fetchBaseQuery({
		baseUrl: URL,
		prepareHeaders: (headers) => {
			headers.set(
				'Content-Type', 
				customType ? customType : 'application/x-www-form-urlencoded;charset=UTF-8'
			)
			headers.set('cache-control', 'no-cache, no-store, must-revalidate')
			headers.set('Pragma', 'no-cache')
			headers.set('Expires', '0')
			headers.set('x-api-key', RP_ENV.API_KEY)

			return headers
		},
		mode: 'cors',
	})

	if (withAuthenticatingHeaders) {
		baseQuery = fetchBaseQuery({
			baseUrl: URL,
			prepareHeaders: (headers, { getState }) => {
				const { Authentication } = getState() as RootState

				const token = getAuthenticationToken(Authentication)

				headers.set('x-api-key', RP_ENV.API_KEY)
				headers.set(
					'Content-Type',
					customType ? customType : 'application/json'
				)
				headers.set('cache-control', 'no-cache, no-store, must-revalidate')
				headers.set('Pragma', 'no-cache')
				headers.set('Expires', '0')
				headers.set('Access-Control-Allow-Headers', '*')

				if(token){
					headers.set('Authorization', `Bearer ${token}`)
					headers.set('authorizationToken', 'allow')
				}

				return headers
			},
			mode: 'cors',
		})
	}

	// return retry(baseQuery, { maxRetries })
	return (args, api, extraOptions) => {
		// Check if the request has a custom maxRetries
		const retries = args.max_retries !== undefined ? args.max_retries : maxRetries

		// Apply the retry logic to the baseQuery with the selected retry count
		const queryWithRetry = retry(baseQuery, { maxRetries: retries })

		// Execute the query
		return queryWithRetry(args, api, extraOptions)
	}
}

const getAuthenticationToken = (slice: AuthenticationStoreData) => {
	const authFromSession = getCookie(APP_CONFIG.AUTHENTICATION.SESSION_LABEL)

	// Checks to see if a user token can be obtained from session
	if (authFromSession && authFromSession.token) {
		return authFromSession.token
	}

	return slice.WebApp.accessToken // Returns the token used by the app if the user is not found
}
