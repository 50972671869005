/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import SectionSeparator from '../../sections/section-separator'

// Styles
import { useHistory } from 'react-router-dom'
import {
	FormButtonsContainer,
	FormFieldContianer,
	OrangeButton,
	PageTitle, SectionContainer,
} from './styles'
import { useUserChangePasswordMutation } from '../../../../app/services'

// Sections

function ForgotPassword(): ReactElement {

	const history = useHistory()

	const token = history.location.pathname

	const [password, setPassword] = useState({
		new: '',
		confirm: '',
		showNew: false,
		showConfirm: false
	})

	const [error, setError] = useState({
		new: '',
		confirm: '',
		submit: ''
	})

	const blurErrorNew = () => {
		setError(prevState => ({ ...prevState, new: password.new.length < 10 ? 'Password must be at least 10 characters' : '' }))
	}

	const blurErrorConfirm = () => {
		setError(prevState => ({ ...prevState, confirm: password.confirm !== password.new ? 'Password does not match' : '' }))
	}

	const isValid = () => {
		setError(prevState => ({ ...prevState, submit: '' }))
		const x = password.new.length < 10 ? 'Password must be at least 10 characters' : ''
		const y = password.confirm !== password.new ? 'Password does not match' : ''

		setError(prevState => ({
			...prevState,
			new: x,
			confirm: y
		}))

		return (x === '' && y === '')
	}
	const [userChangePasswordMutation, { data, isError, isLoading }] = useUserChangePasswordMutation()

	const handleSubmit = async () => {
		if (isValid()) {
			const payload = {
				'token': token.split('/')[2],
				'password': password.new
			}
			try {
				await userChangePasswordMutation(payload)
			} catch (error) {
				console.log(error)
			}
		} else if (!token?.split('/')[2]) {
			setError(prevState => ({ ...prevState, submit: 'Something went wrong, Invalid Token!!' }))
		}
	}

	useEffect(() => {
		if(data){
			if (data.success) {
				window.location.href = '/'
			} else {
				setError(prevState => ({ ...prevState, submit: 'Something went wrong, please try again.' }))
			}
		}

		if(isError){
			setError(prevState => ({ ...prevState, submit: 'Something went wrong, please try again.' }))
		}
	},[data, isError])

	const handleTogglePassword = (id: string) => {
		setPassword((prevState: any) => ({ ...prevState, [id]: !prevState[id] }))
	}

	return (
		<>
			<div className="mnmd-block mnmd-block--fullwidth single-header-wrap">
				<div className="container">
					<header className="single-header">
						<PageTitle>Reset your password</PageTitle>
					</header>
				</div>
			</div>
			<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap">
				<div className="container">
					<SectionContainer>
						<FormFieldContianer>
							<div className={'form-label'} >New Password (minimum 10 characters)</div >
							<div className={'form-value'} >
								<input type={password.showNew ? 'text' : 'password'} value={password.new} style={{ borderColor: error.new ? '#e01a00' : '#CCCCCC' }}
									onBlur={blurErrorNew}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setPassword(prevState => ({ ...prevState, new: event.target.value })) }} />
								<span className="eye-toggle-password-icon" onClick={() => handleTogglePassword('showNew')}>{password.new?.length === 0 ? '' : password.showNew ? <span className="material-symbols-outlined">visibility_off</span> : <span className="material-symbols-outlined">visibility</span>}</span>
								{error.new && <span style={{ marginTop: '5px', marginBottom: '0px', color: '#e01a00', fontSize: '12px' }}>{<><img style={{ width: '12px' }} src={'img/icons/icon_error_warning.svg'} />&nbsp;{error.new}</>}</span>}
							</div>
						</FormFieldContianer>
						<FormFieldContianer>
							<div className={'form-label'} >Confirm Password</div >
							<div className={'form-value'} >
								<input type={password.showConfirm ? 'text' : 'password'} style={{ borderColor: error.confirm ? '#e01a00' : '#CCCCCC' }} value={password.confirm}
									onBlur={blurErrorConfirm}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setPassword(prevState => ({ ...prevState, confirm: event.target.value })) }} />
								<span className="eye-toggle-password-icon" onClick={() => handleTogglePassword('showConfirm')}>{password.confirm?.length === 0 ? '' : password.showConfirm ? <span className="material-symbols-outlined">visibility_off</span> : <span className="material-symbols-outlined">visibility</span>}</span>
								{error.confirm && <span style={{ marginTop: '5px', marginBottom: '0px', color: '#e01a00', fontSize: '12px' }}>{<><img style={{ width: '12px' }} src={'img/icons/icon_error_warning.svg'} />&nbsp;{error.confirm}</>}</span>}
							</div>

						</FormFieldContianer>
						{isLoading && <p style={{ fontSize: '12px' }}><><img style={{ width: '12px', filter: 'brightness(0) invert(0)' }} src={'img/icons/icon_error_warning.svg'} />&nbsp;Loading, please wait...</></p>}
						{error.submit && <p style={{ color: '#e01a00', fontSize: '12px' }}>{<><img style={{ width: '12px' }} src={'img/icons/icon_error_warning.svg'} />&nbsp;{error.submit}</>}</p>}
						<br />
					</SectionContainer>
					<SectionSeparator position={'horizontal'} ></SectionSeparator>
					<FormButtonsContainer>
						<div><OrangeButton onClick={handleSubmit}>RESET PASSWORD</OrangeButton></div>
					</FormButtonsContainer>
				</div>
			</div>
		</>
	)
}

export default ForgotPassword